import styled, { css } from "styled-components";

import Button from '../../components/Button';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Title from "../../components/Title";
import { shade } from "polished";

interface DetailsItemMobileProps {
    color?: string;
}

interface CurrentStepProps {
    color: string;
}

interface StepContainerItemHeaderLeftProps {
    color: string;
    isCurrentStep?: boolean;
}

interface StepContainerItemProps {
    color: string;
}

interface ButtonActionAddDueDateProps {
    isFilled: boolean;
}

interface ButtonActionAddTagProps {
    isFilled: boolean;
}

interface TaskTagProps {
    color: string;
}

interface ContainerFormInitProps {
    hide?: boolean;
}

interface BtnCompleteCardProps {
    complete?: string;
}

interface ButtonGroupProps {
    active: boolean;
}

interface IconHeaderProps {
    color?: string;
}

interface ContainerButtonActionProps {
    classFastBtn?: string;
    classActionBtn?: string;
}

interface ButtonActionFastProps {
    colorFont?: string;
}

export const DialogContainer = styled(Dialog)`
    .MuiDialog-paperWidthLg {
        max-width: 1500px;
    }

    .MuiDialog-paperScrollPaper {
        max-height: 100%;
    }
`;

export const Content = styled(DialogContent)`
    background-color: white;   
    height: 100%; //calc(100% - 80px);
    padding: 0px !important;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    
    width: auto;
    height: 100%;
`;

export const DialogTop = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: calc(65% - 130px);
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;
`;

export const DialogTopCenter = styled.div`
    width: 35%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
`;

export const CurrentStepContainer = styled.div<CurrentStepProps>`
    display: flex;
    margin-left: 0px !important;
    background-color: #61bd4f1a !important;
    padding: 3px;
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 6px;
    justify-content: center;
    align-content: center;
    border: none;
    justify-items: center;
    align-items: center;
    height: 75%;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px !important;
        padding: 0px !important;

        svg {
            margin-right: 10px;
            margin-left: 5px;
            color: white;
        }
    }

    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px !important;
        color: white;
    }
`;

export const ArchivedContainer = styled.div<CurrentStepProps>`
    display: flex;
    margin-left: 0px !important;
    background-color: #61bd4f1a !important;
    padding: 5px 10px 5px 10px;
    color: white;
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 6px;
    justify-content: center;
    align-content: center;
    border: none;
    justify-items: center;
    align-items: center;
    height: 75%;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}

    svg {
        margin-right: 10px;
        margin-left: 5px;
        color: white;
    }

    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px !important;
        color: white;
    }
`;

export const CurrentStepName = styled.div``;

export const SeparatorTop = styled.div`
    margin-right: 30px;
`;

export const DialogTopItemValue = styled.div`
    color: #4d4d4d;
    font-size: 15px;
`;

export const DialogTopItemContainer = styled.div`

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        margin-top: 2px;
        text-transform: uppercase;
    }
`;

export const DialogTopItemContainerUser = styled.div`

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        margin-top: 2px;
        text-transform: uppercase;
    }

`;

export const DialogTopRight = styled.div`
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const DropDownMenuContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;        
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;
        margin-left: 15px !important;

    .col-hed-conf {
        border: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4d4d4d;
        font-weight: 500;
        font-size: 13px;
        padding: 5px 10px;
        width: 45px !important;
        height: 35px !important;
        
        
        svg {
            font-size: 18px;
            margin-top: 1px;
        }

        :hover {
            background-color: #f4f4f4;        
            border-radius: 5px;
        }
    }

    .col-hed-con-conf {
        right: 0px;
        position: absolute;
        min-width: 250px;
    }
`;

export const BtnCloseCard = styled.button`    
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const BtnMoreOption = styled.button`    
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;
    margin-left: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 15px;

        ${props => props.color && css`
            background-color: ${props.color + '1e'};
        `}

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const ContainerLeft = styled(Grid)`
    overflow: auto;
    border-right: 1px solid #eef2f8;
    padding: 10px;
    max-height: 80vh;
    min-height: 80vh;

    height: 100%;
    overflow-y: auto;
`;

export const ContainerCenter = styled(Grid)`
    display: flex;
    flex-direction: column;

    overflow: auto;
    border-right: 1px solid #eef2f8;
    padding: 0px;
    max-height: 80vh;
    position: relative;

    overflow-y: auto;
    height: 100vh;
`;

export const ContainerCenterForm = styled.div<{ isOpen?: boolean }>`
    flex: 1;
    overflow-y: auto;
    padding: 10px;
`;

export const ContainerCenterFooter = styled.div<{ isOpen?: boolean }>`
    padding: 10px;
    padding-top: 0px;
    padding-left: 0px;
    border-top: 1px solid #eef2f8;
    background-color: white;
    box-shadow: 0px -2px 20px 1px rgb(238 242 248);
`;

export const FollowCardContainer = styled.div`
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const FollowCardButtonLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 48px;
`;

export const FollowCardDesc = styled.div`
    font-size: 12px;    
    font-weight: 400;    
    color: #4d4d4d;
    margin-right: 10px;
    margin-top: 2px;
`;

export const FollowCardButtonRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 100%;
`;

export const FollowButton = styled(Button)`
    height: 30px;    
    justify-content: center;
    overflow: hidden;    
    color: #4d4d4d;

    width: 150px;
    background-color: transparent;
    font-weight: 400;
    font-size: 13px;
    padding: 5px 10px;
    cursor: pointer;

    svg {
        margin-right: 5px;
        font-size: 12px;
    }

    :hover {
        background-color: white;
        border-radius: 5px;
        color: #4d4d4d;
        border: 1px solid #f23b5c;
    }
`;

export const ContainerRight = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
    max-height: 80vh;

    height: 100%;
    overflow-y: auto;
`;

export const ContainerRightTop = styled.div``;

export const ContainerRightTitle = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    font-size: 12px;
    color: #84939f;
    font-weight: 500;
    margin-top: 2px;
    text-transform: uppercase;
`;

export const Separator = styled.div`
    border: 2px solid transparent;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const ContainerButtonAction = styled.div<ContainerButtonActionProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    ${props => props.classFastBtn && css`
        :hover {
            .${props.classFastBtn} {
                display: flex;
            }
        }
    `}

    ${props => props.classActionBtn && css`
        :hover {
            .${props.classActionBtn} {
                border-radius: 5px 0px 0px 5px;            
            }
        }
    `}

`

export const ButtonAction = styled(Button)`
    height: 35px;        
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;

    svg {
        width: 15px;
    }

    
`;

export const ButtonActionFast = styled(Button) <ButtonActionFastProps>`
    display: none;
    height: 35px;    
    width: 40px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 5px 5px 0px;

    svg {
        width: 15px;
    }

    ${(props) => props.colorFont && css`
        color: ${props.colorFont};
    `}

`;

export const ButtonActionBack = styled(Button)`
    height: 35px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    color: #4d4d4d;

    svg {
        width: 15px;
        color: #4d4d4d;
    }
`;

export const ButtonActionAddTag = styled(Button) <ButtonActionAddTagProps>`
    height: 35px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    color: #4d4d4d;
    background-color: #9e37ed1a;

    svg {
        width: 15px;
    }

    :hover {
        background: ${shade(0.2, '#9e37ed1a')}
    }

    ${(props) =>
        props.isFilled &&
        css`

            margin-bottom: 0px !important;
            border-radius: 5px 5px 0px 0px !important;

            :hover {
                border-radius: 5px 5px 0px 0px;
                background: ${shade(0.2, '#9e37ed1a')}
            }
  `}
`;

export const ButtonActionAddDueDate = styled(Button) <ButtonActionAddDueDateProps>`
    height: 35px;
    margin-bottom: 10px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    color: #4d4d4d;

    svg {
        width: 15px;
    }

    ${(props) =>
        props.isFilled && //Caso tenha data settada
        css`
            margin-bottom: 0px !important;
            border-radius: 5px 5px 0px 0px !important;
  `}
`;

export const AddDueDateCompContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 0px 0px 5px 5px;
    background-color: #893be9;
    border-top: 1px solid #80808061;
    font-weight: 500;
    color: white;
`;

export const ButtonEditSteps = styled(Button)`
    height: 35px;
    margin-bottom: 10px;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
    background-color: white;
    border: 1px dashed #eef2f8;
    overflow: hidden;

    svg {
        width: 16px;
        height: 16px;
        color: #4d4d4d;
    }

    :hover {

        border: 1px dashed #f23b5c;
        background-color: white;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }
`;

export const ButtonActionActionOff = styled(Button)`
    height: 35px;
    margin-bottom: 10px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    color: #4d4d4d;

    svg {
        width: 15px;
    }
`;

export const ContainerRightBottom = styled.div`
    margin-top: auto;
`;

export const ContainerFormInit = styled.div<ContainerFormInitProps>`
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 25px;

    ${props => props.hide === false && css`
        display: none; 
    `}
`;

export const ContainerFormCurrent = styled.div`
    form {
        padding: 25px 10px 0px 10px;
    }
`;

export const StepContainerItem = styled.div<StepContainerItemProps>`
    border: 1px solid #eef2f8;
    border-radius: 5px;;
    padding: 10px;
    margin-top: 15px;

    ${props => props.color && css`
        border: 1px solid ${props.color}; 
    `}
`;

export const StepContainerItemHeader = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const StepContainerItemHeaderLeft = styled.div<StepContainerItemHeaderLeftProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 70px;
    max-width: 120px !important;
    min-height: 50px;
    border: 1px solid #4d4d4d; 
    background-color: #4d4d4d1a;
    border-radius: 5px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        
        svg {
            color: #4d4d4d;
            width: 23px;
            height: 23px;
        }
    }

    svg {
        color: #4d4d4d;
        width: 23px;
        height: 23px;
    }

    ${props => props.color && css`
        border: 1px solid ${props.color}; 
        background-color:${props.color + "1a"};

        div {
            svg {
                color: ${props.color};
            }
        }

        svg {
            color: ${props.color};
        }
    `}

    ${props => props.color && props.isCurrentStep === true && css`
        border: 1px solid ${props.color}; 
        background-color:${props.color};

        div {
            svg {
                color: white;
            }
        }

        svg {
            color: white;
        }
    `}
`;

export const StepContainerItemHeaderDescription = styled.div`
    margin-left: 10px;
    width: calc(100% - 70px);
    position: relative;
`;

export const StepContainerItemHeaderRight = styled.button`
    height: 100%;
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: none;
    background: transparent;

    svg {
        height: 20px;
        width: 20px;
        color: #4d4d4d;
    }
`;

export const StepContainerItemTitle = styled(Title)`
    
`;
export const StepContainerItemMadeBy = styled.p`
    display: flex;
    align-items: center;
    font-size: 13px;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
`;

export const TaskBodyItem = styled.div`
  margin-top: 15px;
`;

export const TaskBodyItemHead = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 7px;

  svg {
    min-width: 15px;
    font-size: 15px;
    font-weight: 500;
    color: #84939f;
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    font-size: 12px;
    color: #84939f;
    font-weight: 500;
    margin-top: 2px;
    text-transform: uppercase;
  }
`;

export const TaskBodyItemBody = styled.div`
  margin-left: 26px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 15px;
  color: #4d4d4d;
  overflow: hidden;
`;

export const TitleHeader = styled.div`
    margin-left: 10px;

    h1 {
        font-size: 20px;
        font-weight: 500;
        color: black;
        max-height: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const TagHeader = styled.div`
  display: flex;
  justify-content: start;
  align-content: start;
  margin-top: 5px;
`;

export const TaskTag = styled.div<TaskTagProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    min-width: 40px;
    min-height: 20px;
    font-size: 11px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 5px;
    background-color: #f23b5c;
    color: white;
    font-weight: 500;
    margin-right: 5px;

    ${(props) => props.color && css`
      background-color: ${props.color} !important;
    `}

`;

export const BtnCompleteCard = styled.div<BtnCompleteCardProps>`
    display: flex;
    justify-content: center;
    align-items: center;    
    border: 1px dashed gray;    
    color: gray;
    flex-direction: column;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0px;
    cursor: pointer;
    
    ${(props) => props.complete === "S" && css`
        background-color: #60bd4f;
        color: white;
        border: 1px solid #60bd4f;
    `}

    svg {        
        color: gray;
        font-size: 15px;        

        ${(props) => props.complete === "S" && css`
            color: white;
        `}
    }

    span {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;

        ${(props) => props.complete === "S" && css`
            color: white;
        `}
    }

    :hover {
        border: 1px dashed #60bd4f;
        color: #60bd4f;
        
        ${(props) => props.complete === "S" && css`
            opacity: 0.8;
            border: 1px solid #60bd4f;
        `}

        svg {
            color: #60bd4f;

            ${(props) => props.complete === "S" && css`
                color: white;
            `}
        }

        span {
            ${(props) => props.complete === "S" && css`
                color: white;
            `}
        }

    }
`;

export const AddTagContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 0px 0px 5px 5px;
    background-color: #893be9;
    border-top: 1px solid #80808061;
    font-weight: 500;
    color: white;
`;

export const ContainerLoader = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;

    svg {
        width: 45px !important;
        height: 45px !important;
    }
    
`;

export const ContainerLeftContent = styled.div`
   
   padding: 10px;
   padding-top: 0px;

`;

export const FlowDetailContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #efefef;
    padding: 5px;
    padding-left: 25px;
    font-weight: 500;
    letter-spacing: 0.8px;
`;

export const FlowDetailGoTo = styled.button`
    position: absolute;
    right: 20px;
    top: 0px;
    font-size: 11px;
    text-transform: uppercase;
    background-color: #893be9;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 0px 0px 5px 5px;
    border: none;

    :hover {
        background-color: #893be99c;
    }
`;

export const FlowDetailTag = styled.span`
    color: gray;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
`;

export const StepContainerItemSaveStatus = styled.div`
    position: absolute;
    right: 0px;
    top: -3px;
`;

export const ButtonLinkSync = styled.button`
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    width: 145px;
    justify-content: flex-end;
    font-weight: 500;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const CurrentStepHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
`;

export const TimeTrackingContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 2px;
`;

export const DialogTopMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const TitleHeaderMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 15px;

    h1 {
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const CardCloseBtnMobile = styled.span`
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #f23b5c;
`;

export const DetailsContainerMobile = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
`;

export const DetailsItemMobile = styled.div<DetailsItemMobileProps>`
    background-color: #f23b5d1e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    border-radius: 10px;
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;

    ${(props) => props.color && css`
      background-color: ${props.color + '1e'} !important;
    `}

    div {
        font-size: 0px !important;
        color: white !important;
        padding: 0px !important;

        svg {
            margin-right: 10px;
            font-size: 16px;

            ${(props) => props.color && css`
                color: ${props.color} !important;
            `}
        }
    }

    svg {
        margin-right: 10px;
        font-size: 15px;

        ${(props) => props.color && css`
            color: ${props.color} !important;
        `}
    }
`;

export const SubHeaderRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;

export const ButtonGroupLeft = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 14px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 10px 0px 0px 10px;
    border-right: 1px solid #858585;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;

export const ButtonGroupCenter = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 14px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-right: 1px solid #858585;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;

export const ButtonGroupRight = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 14px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 0px 10px 10px 0px;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;

export const ContainerCenterMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    padding: 10px;
`;

export const MoveCardButtonMobile = styled.button`
    margin-top: 20px;
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    background-color: #f23b5d;
    color: white;
    margin-bottom: 20px;
`;

export const CopyBtn = styled.button`
    font-weight: 500;
    padding: 3px 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background: transparent;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #9e37ed1e;        
        border-radius: 5px;
    }
`;

export const DropDownShareMenuContainer = styled.div`

    margin-bottom: 5px;

    .col-hed-con-conf {
        margin-left: 8px;
        max-width: 450px;
    }

    .col-hed-conf {
        font-weight: 500;
        padding: 3px 7px;
        border-radius: 5px;
        font-size: 12px;
        color: #9e37ed;
        cursor: pointer;
        margin-left: 10px;

        svg {
            margin-left: 5px;
        }
    }

`;

export const DropDownInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #101820;
    font-weight: 400;
    font-size: 13px;
    margin-top: 0px;
    border-radius: 10px;
    padding: 10px;

    span { 
        margin-right: 5px;
        color: grey;
    }
`;

export const AutomaticData = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid rgb(238, 238, 238);
    color: rgba(51, 51, 51, 0.9);
    background-color: rgb(248, 248, 248);
`;