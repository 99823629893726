export interface AccessControl {
    id_acess: number;
    page: string;
    action: string;
    permission: string[];
}

export const Access: AccessControl[] = [
    { id_acess: 1, page: "Dashboard", action: "Abrir a dialog de convidar usuários", permission: ["A", "M"] },

    { id_acess: 78, page: "UserMenu", action: "Abrir a tela do time sheet", permission: ["A", "G"] },
    { id_acess: 82, page: "UserMenu", action: "Abrir a aba de Aplicativos na tela de configuração pessoal", permission: ["A"] },

    { id_acess: 10, page: "MyFlows", action: "Adicionar um novo fluxo", permission: ["A", "M"] }, 
    { id_acess: 55, page: "MyFlows", action: "Visualizar o total de cartões", permission: ["A", "M", "V"] }, 
    { id_acess: 11, page: "MyTeam", action: "Abrir a dialog de convidar usuários", permission: ["A", "M"] }, 

    { id_acess: 2, page: "InviteUser", action: "Alterar o tipo dos usuários na dialog de usuários", permission: ["A"] },
    { id_acess: 3, page: "InviteUser", action: "Excluir usuários na dialog de usuários", permission: ["A"] },
    { id_acess: 4, page: "InviteUser", action: "Convidar usuários na dialog de usuários", permission: ["A", "M"] },
    { id_acess: 47, page: "InviteUser", action: "Remover um usuário do ambiente", permission: ["A"] },

    { id_acess: 5, page: "Flow", action: "Abrir a dialog de convidar usuários", permission: ["A", "M"] },
    { id_acess: 12, page: "Flow", action: "Abrir a dialog para inserir novo registro", permission: ["A", "M", "R", "I", "S"] }, 
    { id_acess: 13, page: "Flow", action: "Abrir a dialog do menu lateral de configuração", permission: ["A", "M"] }, 
    { id_acess: 15, page: "Flow", action: "Abrir a tela para edição das configurações gerais", permission: ["A"] }, 
    { id_acess: 19, page: "Flow", action: "Abrir a tela para edição do formulário inicial", permission: ["A"] }, 
    { id_acess: 20, page: "Flow", action: "Abrir a tela para edição das etapas do fluxo", permission: ["A"] }, 
    { id_acess: 21, page: "Flow", action: "Abrir a dialog para configuração da visualização do fluxo", permission: ["A"] }, 
    { id_acess: 22, page: "Flow", action: "Abrir a dialog para visualização dos cartões arquivados", permission: ["A", "M"] }, 
    { id_acess: 23, page: "Flow", action: "Ativar o modo teste do fluxo", permission: ["A"] }, 
    { id_acess: 24, page: "Flow", action: "Exclusão do fluxo", permission: ["A"] }, 
    { id_acess: 51, page: "Flow", action: "Adicionar novo elemento no painel", permission: ["A"] }, 
    { id_acess: 52, page: "Flow", action: "Abrir o menu de opções dos elementos (Excluir, Editar e Duplicar)", permission: ["A"] }, 
    { id_acess: 53, page: "Flow", action: "Editar o layout dos elementos do painel", permission: ["A"] },
    { id_acess: 54, page: "Flow", action: "Editar o título dos elementos do painel", permission: ["A"] },
    { id_acess: 69, page: "Flow", action: "Drag and Drop do Cartão no Kanban", permission: ["A", "M"] },
    { id_acess: 70, page: "Flow", action: "Exportar os dados do Kanban", permission: ["A", "M"] },
    { id_acess: 72, page: "Flow", action: "Salvar configuração atual da visualização do Fluxo", permission: ["A"] },
    { id_acess: 73, page: "Flow", action: "Importação de dados em massa", permission: ["A"] },
    { id_acess: 74, page: "Flow", action: "Acesso a Aba de Atividades", permission: ["A"] },
    { id_acess: 75, page: "Flow", action: "Acesso a alteração da visualização da lista", permission: ["A", "M", "R", "I", "V"] },
    { id_acess: 79, page: "Flow", action: "Acesso a configuração de automações", permission: ["A"] },
    { id_acess: 80, page: "Flow", action: "Acesso a configuração de integrações", permission: ["A"] },
    { id_acess: 81, page: "Flow", action: "Acesso ao compartilhamento do formulário", permission: ["A"] },
    { id_acess: 92, page: "Flow", action: "Acesso a execução de Bulk Actions", permission: ["A", "M"] },

    { id_acess: 76, page: "ViewFlow", action: "Salvar a visualização padrão do fluxo", permission: ["A"] },
    { id_acess: 77, page: "ViewFlow", action: "Criar uma nova visualização no fluxo", permission: ["A", "M", "R", "I", "V"] },

    { id_acess: 25, page: "Card", action: "Visualizar a coluna de ação do card", permission: ["A","M","R", "I"] }, 
    { id_acess: 26, page: "Card", action: "Link para a edição da estrutura do flow", permission: ["A"] }, 
    { id_acess: 27, page: "Card", action: "Concluir o card", permission: ["A", "M", "R", "I"] }, 
    { id_acess: 29, page: "Card", action: "Inserir novos arquivos no card", permission: ["A", "M", "R", "I"] }, 
    { id_acess: 44, page: "Card", action: "Inserir novos comentários no card", permission: ["A", "M", "R", "I", "S"] }, 
    { id_acess: 50, page: "Card", action: "Pre Salvar a resposta do formulário da etapa atual", permission: ["A", "M", "R", "I"] }, 
    { id_acess: 56, page: "Card", action: "Editar o responsável pelo cartão", permission: ["A", "M"] }, 
    { id_acess: 57, page: "Card", action: "Tomar alguma ação nos botões de ação do rodapé do cartão (Arquivar, Desarquivar, Excluir)", permission: ["A", "M"] }, 
    { id_acess: 58, page: "Card", action: "Enviar um e-mail a partir do cartão (Novo E-mail)", permission: ["A", "M", "R", "I"] }, 
    { id_acess: 91, page: "Card", action: "Adicionar um novo seguidor no cartão", permission: ["A", "M"] }, 

    { id_acess: 62, page: "CheckListTab", action: "Criar um novo checklist", permission: ["A", "M", "R", "I"] },
    { id_acess: 63, page: "CheckListTab", action: "Editar os checklist disponíveis", permission: ["A", "M", "R", "I"] },

    { id_acess: 48, page: "CardMail", action: "Ter alguma ação nos emails do cartão", permission: ["A", "M", "R", "I"] }, 

    { id_acess: 49, page: "Email", action: "Ter alguma ação de envio ou resposta nos emails", permission: ["A", "M", "R", "I"] }, 
    
    { id_acess: 59, page: "EmailBox", action: "Enviar um e-mail a partir do botão enviar email", permission: ["A", "M", "R", "I"] }, 
    { id_acess: 60, page: "EmailBox", action: "Acessar as configurações do e-mail", permission: ["A"] }, 
    { id_acess: 61, page: "EmailBox", action: "Vincular um e-mail a um cartão existente", permission: ["A", "M", "R", "I"] }, 

    { id_acess: 46, page: "FieldContainer", action: "Criar regras de auto completar os campos", permission: ["A"] }, 
    
    { id_acess: 45, page: "HistoryItem", action: "Excluir ou fixar comentários no card", permission: ["A", "M", "R", "I"] },

    { id_acess: 30, page: "Attachments", action: "Inserir ou excluir arquivos no card", permission: ["A", "M", "R", "I"] }, 

    { id_acess: 31, page: "TemplateCentral", action: "Criar um novo fluxo", permission: ["A", "M"] }, 
    { id_acess: 84, page: "TemplateCentral", action: "Acesso à central de templates", permission: ["A", "M"] }, 
    { id_acess: 87, page: "TemplateCentral", action: "Visualização dos templates sugeridos", permission: ["A", "M"] }, 

    { id_acess: 32, page: "NewAnswer", action: "Link para edição do formulário e compartilhamento público", permission: ["A"] }, 

    { id_acess: 28, page: "FormAnswerRecord", action: "Editar os campos de um card", permission: ["A", "M", "R", "I"] },
    
    { id_acess: 42, page: "Record", action: "Excluir um registro de um cadastro", permission: ["A", "M"] },
    { id_acess: 43, page: "Record", action: "Editar os campos do cadastro", permission: ["A"] },

    { id_acess: 16, page: "Column", action: "Abrir as opções de configuração das colunas do kanban", permission: ["A"] }, 
    { id_acess: 18, page: "Column", action: "Abrir as opções de agregação das colunas do kanban", permission: ["A", "M"] },

    { id_acess: 17, page: "Kanban", action: "Adicionar novas etapas", permission: ["A"] }, 

    { id_acess: 14, page: "FilterBar", action: "Abrir a dialog para edição do menu de visualização", permission: ["A", "M"] }, 

    { id_acess: 6, page: "FlowList", action: "Abrir a dialog de convidar usuários", permission: ["A", "M"] },
    { id_acess: 8, page: "FlowList", action: "Abrir as configurações do fluxo", permission: ["A"] },
    { id_acess: 9, page: "FlowList", action: "Adicionar um novo fluxo", permission: ["A", "M"] },    

    { id_acess: 7, page: "RegisterList", action: "Abrir a dialog de convidar usuários", permission: ["A", "M"] },
    { id_acess: 33, page: "RegisterList", action: "Abrir as configurações do cadastro", permission: ["A"] }, 
    { id_acess: 34, page: "RegisterList", action: "Adicionar um novo cadastro", permission: ["A"] },

    { id_acess: 35, page: "Register", action: "Abrir a dialog de convidar usuários", permission: ["A", "M"] },
    { id_acess: 36, page: "Register", action: "Abrir a dialog para inserir novo registro", permission: ["A", "M", "R", "I"] }, 
    { id_acess: 37, page: "Register", action: "Abrir a dialog do menu lateral de configuração", permission: ["A", "M"] },
    { id_acess: 38, page: "Register", action: "Abrir a tela para edição das configurações gerais", permission: ["A"] }, 
    { id_acess: 39, page: "Register", action: "Abrir a tela para edição dos campos do cadastro", permission: ["A"] }, 
    { id_acess: 40, page: "Register", action: "Abrir a dialog para configuração da visualização do cadastro", permission: ["A", "M"] }, 
    { id_acess: 41, page: "Register", action: "Abrir a dialog para a exclusão do cadastro", permission: ["A"] }, 
    { id_acess: 68, page: "Register", action: "Importação de dados em massa", permission: ["A"] },
    { id_acess: 71, page: "Register", action: "Exportar os dados do cadastro", permission: ["A"] },
    { id_acess: 86, page: "Register", action: "Visualização do menu lateral de cadastros", permission: ["A", "M", "V"] },

    { id_acess: 64, page: "Workspace", action: "Abrir a dialog de convidar usuários", permission: ["A"] },
    { id_acess: 65, page: "Workspace", action: "Abrir a tela para edição das configurações gerais", permission: ["A"] },
    { id_acess: 66, page: "Workspace", action: "Edição da descrição geral do workspace", permission: ["A"] },
    { id_acess: 67, page: "Workspace", action: "Adicionar/Remover um flow do workspace", permission: ["A"] },
    { id_acess: 83, page: "Workspace", action: "Criação de um novo Workspace", permission: ["A", "M"] },
    { id_acess: 85, page: "Workspace", action: "Visualização do menu lateral de workspaces", permission: ["A", "M", "V"] },

    { id_acess: 88, page: "StatusPlan", action: "Visualização do status do plano", permission: ["A", "M"] }, 

    { id_acess: 89, page: "TaskList", action: "Visualização da lista de tarefas", permission: ["A", "M", "V"] }, 
    
    { id_acess: 90, page: "CompanyConfig", action: "Acesso ao menu de alteração dos dados da empresa", permission: ["A"] }, 
];

export default function getAccessControl(
    id: number,
    type: string
): boolean {

    const accessItem = Access.find((access) => access.id_acess === id);

    if (accessItem !== undefined) {
        if (accessItem.permission.includes(type)) {
            return true;
        }
    }

    return false;
}
